import { Component } from 'react'
import PropTypes from 'prop-types';

class imageResize extends Component {
    
    render() {
        let Bsize = ''
        imageResize.propTypes = {
            serie: PropTypes.object.isRequired,
            Class: PropTypes.string,
            alter: PropTypes.string.isRequired
        };
        const serie = this.props.serie
        const Class = this.props.class
        const alter = this.props.alter
        return (
            <img
                sizes={serie.imgs.toReversed().map((image, index) => {
                    if(image[1] === undefined){
                        return []
                    }
                    const Size = ["(-webkit-min-device-pixel-ratio: 2) 50vw"];
                    const size = image[1].toString();
                    if(index === 0){
                        Size.push("(min-width "+ size +"px) " + size + "w");
                        Bsize = size - 1 ;
                    } else {
                        Size.push("(max-width "+ Bsize +"px) " + size + "w")
                        Bsize = size - 1 ;
                    }
                        return Size
                })}
                srcSet={serie.imgs.toReversed().map((image) => {
                    if(image[1] === undefined){
                        return []
                    }
                    const Srcset = [];
                    const Zize = image[1].toString();
                    const link = image[0].toString();
                    Srcset.push(link + " " + Zize + "w");
                    return Srcset
                })}
                src={serie.post_image.full_image_url}
                loading="lazy"
                className={Class}
                alt={alter}
            />
        )
    }

}

export default imageResize