import React, { Component } from "react";
import logoImage from "../../images/latv-logo.png";
import $ from "jquery";
import { Modal } from "react-bootstrap";
let flagApi = false;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Menus: [],
      SecondaryMenu: "",
      posts: [],
      tags: [],
      content: "",
      status: "start",
      showHide: false,
      searchText: "",
      mobileDevice: false,
      showMenu: false,
      showDropdown: false,
    };
  }

  loadMenu = async () => {
    const content = this.renderItem(this.props.menu);
    const secondaryContent = this.renderSecondaryItem(this.props.secondaryMenu);
    this.setState({
      content: content,
      Menus: this.props.menu,
      SecondaryMenu: secondaryContent,
      Loading: false,
    });
  };
  searchType = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };
  handleSearch = (e) => {
    e.preventDefault();
    window.location.href =
      window.location.origin + `/search/${this.state.searchText}`;
  };

  renderItem = (menus) => {
    console.log(menus);
    const menusContent = menus.map((menu, i) => {
      var url = menu.url;
      var pathname = url;
      if (menu.children.length !== 0) {
        return (
          <li className="nav-item dropdown" key={menu.ID}>
            <a
              href={url}
              className="nav-link dropdown-toggle latv-network-menu-title"
              id="navbardrop"
              data-bs-toggle="dropdown"
              title={menu.title}
              aria-expanded="false"
            >
              {menu.title}
            </a>
            <ul className="dropdown-menu">
              {menu.children.map((subMenu, j) => {
                var url = subMenu.url;
                var pathname = url;
                // }
                if (subMenu.children.length !== 0) {
                  return (
                    <>
                      <li id={"ele-" + subMenu.ID} title={subMenu.title}>
                        <a className="dropdown-item" href={pathname}>
                          {subMenu.title}
                        </a>
                      </li>
                      {subMenu.children.map((childMenu, k) => {
                        var url = childMenu.url;
                        var pathname = url;
                        return (
                          <li key={k} title={childMenu.title}>
                            <a className="dropdown-item" href={pathname}>
                              {childMenu.title}
                            </a>
                          </li>
                        );
                      })}
                    </>
                  );
                } else {
                  return (
                    <li href={pathname} key={j} title={subMenu.title}>
                      <a className="dropdown-item" href={pathname}>
                        {subMenu.title}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </li>
        );
      } else {
        return (
          <li href={pathname} className="nav-item" key={i}>
            <a href={pathname} title={menu.title}>
              <button className="nav-link" title={menu.title}>
                {menu.title}
              </button>
            </a>
          </li>
        );
      }
    });

    return menusContent;
  };

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  renderSecondaryItem = (menus) => {
    const menusContent = menus.map((menu, i) => {
      const url = menu.url;
      const menuTitle = menu.title;
      return (
        <span>
          <a className="quickMenuItem" href={url}>
            {menuTitle}
          </a>
          &nbsp;{i !== menus.length - 1 ? "|" : ""}&nbsp;
        </span>
      );
    });
    return menusContent;
  };

  componentDidMount = () => {
    if (!flagApi) {
      this.loadMenu();

      $(document).click((event) => {
        const isNavbarCollapseTarget = $(event.target).closest(
          ".navbar-collapse"
        ).length;
        const isNavbarCollapseOpen = $(".navbar-collapse").hasClass("show");

        if (!isNavbarCollapseTarget && isNavbarCollapseOpen) {
          $(".navbar-toggler").click(); // Trigger the toggle button click
        }
      });
      $(".navbar-toggler").click(() => {
        $("#navbarCollapse").toggleClass("show");
      });

      flagApi = true;
    }
  };

  render() {
    return (
      <>
        <div className="supraHeader container-fluid d-none d-lg-block">
          <div className="row">
            <div className="col-lg-10 supraTagline">
              <p>Inclusive Media Representing Our Cultura</p>
            </div>
            <div className="col-lg-2 socialNetworks">
              <a
                href="https://www.instagram.com/latvnetwork/"
                target="_blank"
                rel="noreferrer"
                data-toggle="tooltip"
                title=""
                data-original-title="Instagram"
              >
                <i className="btn fa-brands fa fa-instagram white-txt"></i>
              </a>
              <a
                href="https://www.facebook.com/LatvNetwork/"
                target="_blank"
                rel="noreferrer"
                data-toggle="tooltip"
                title=""
                data-original-title="Facebook"
              >
                <i className="btn fa-brands fa fa-facebook white-txt"></i>
              </a>
              <a
                href="https://www.youtube.com/user/LATVNetwork"
                target="_blank"
                rel="noreferrer"
                data-toggle="tooltip"
                title=""
                data-original-title="Youtube"
              >
                <i className="btn fa-brands fa fa-youtube white-txt"></i>
              </a>
              <a
                href="https://twitter.com/latvnetwork"
                target="_blank"
                rel="noreferrer"
                data-toggle="tooltip"
                title=""
                data-original-title="Twitter"
              >
                <i className="btn fa-brands fa fa-twitter white-txt"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="sticky-top">
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img src={logoImage} alt="LATV" width="auto" height="40" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  {!this.state.Loading ? <>{this.state.content}</> : <></>}
                  <li className="nav-item searchIcon">
                    <button
                      className=""
                      onClick={() => this.handleModalShowHide()}
                      data-toggle="tooltip"
                      title="Search"
                    >
                      <p>
                        <i className="fa fa-search"></i>
                      </p>
                    </button>
                  </li>
                  <li className="nav-item d-none d-lg-block">
                    <a href="#" className="watchNow">
                      WATCH NOW
                    </a>
                  </li>
                  <li className="nav-item d-block d-lg-none col-12 text-center socialNav">
                    <div className="socialNetworks">
                      <a
                        href="https://www.instagram.com/latvnetwork/"
                        target="_blank"
                        rel="noreferrer"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Instagram"
                      >
                        <i className="btn fa-brands fa fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/LatvNetwork/"
                        target="_blank"
                        rel="noreferrer"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Facebook"
                      >
                        <i className="btn fa-brands fa fa-facebook"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/user/LATVNetwork"
                        target="_blank"
                        rel="noreferrer"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Youtube"
                      >
                        <i className="btn fa-brands fa fa-youtube"></i>
                      </a>
                      <a
                        href="https://twitter.com/latvnetwork"
                        target="_blank"
                        rel="noreferrer"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Twitter"
                      >
                        <i className="btn fa-brands fa fa-twitter"></i>
                      </a>
                    </div>
                  </li>
                </ul>

                {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">CULTURE</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">QUEER</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">LATINA</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">ROOTS</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">SERIES</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">SPECIALS</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">MORE</a>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item d-none d-lg-block">
                                    <i className="fa-solid fa-search"></i>
                                </li>
                                <li className="nav-item d-none d-lg-block">
                                    <a href="#" className="watchNow">WATCH NOW</a>
                                </li>
                                <li className="nav-item d-block d-lg-none col-12 text-center socialNav">
                                    <div className="socialNetworks">
                                        <a href="https://www.instagram.com/latvnetwork/" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Instagram"><i className="btn fa-brands fa fa-instagram"></i></a>
                                        <a href="https://www.facebook.com/LatvNetwork/" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Facebook"><i className="btn fa-brands fa fa-facebook"></i></a>
                                        <a href="https://www.youtube.com/user/LATVNetwork" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Youtube"><i className="btn fa-brands fa fa-youtube"></i></a>
                                        <a href="https://twitter.com/latvnetwork" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Twitter"><i className="btn fa-brands fa fa-twitter"></i></a>
                                    </div>
                                </li>
                            </ul> */}
              </div>
            </div>
          </nav>
          <div className="mobileQuickMenu d-block d-lg-none">
            <span>
              <a className="quickMenuItem" href="https://latv.com/culture">
                CULTURE
              </a>
              &nbsp;|&nbsp;
            </span>
            <span>
              <a className="quickMenuItem" href="https://latv.com/queer">
                QUEER
              </a>
              &nbsp;|&nbsp;
            </span>
            <span>
              <a className="quickMenuItem" href="https://latv.com/latina">
                LATINA
              </a>
              &nbsp;|&nbsp;
            </span>
            <span>
              <a className="quickMenuItem" href="https://latv.com/afrolatino">
                AFROLATINO
              </a>
              &nbsp;|&nbsp;
            </span>
            <span>
              <a className="quickMenuItem" href="https://latv.com/series">
                SERIES
              </a>
              &nbsp;|&nbsp;
            </span>
            <span>
              <a className="quickMenuItem" href="https://latv.com/schedule">
                SCHEDULE
              </a>
              &nbsp;&nbsp;
            </span>
          </div>
        </div>
        <Modal
          show={this.state.showHide}
          dialogClassName="my-modal"
          animation={false}
        >
          <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={() => this.handleSearch()}>
              <div className="container">
                <div className="row">
                  <div className="col-md-11">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.searchType}
                        id="s"
                        name="s"
                        placeholder="Search Keywords..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <button
                      type="submit"
                      onClick={this.handleSearch}
                      className="search-btn"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default Header;
