import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import "../../NewCss/lgbtPlaceholder.css";
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import VideoComponent from "../VideoComponent/VideoComponent.js";
import SpecialsCarousel from "../Specials/SpecialsCarousel.js";

class QueerHomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      Loading: true,
      Posts: [],
      CategoriesColors: [],
      dwidth: 0,
      sasSetup: false,
    };
  }

  componentDidMount() {
    this.setState({ dwidth: $(window).width() });
    // console.log(this.state.dwidth,$(window).width())
    this.handleResize();
    this.resizeListener = window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    let vwidth = $(window).width();
    let divider;
    if (window.innerWidth >= 1380) {
      divider = 4;
    } else if (window.innerWidth < 1380 && window.innerWidth >= 822) {
      divider = 3;
    } else if (window.innerWidth < 822 && window.innerWidth >= 465) {
      divider = 2;
    } else if (window.innerWidth < 465) {
      divider = 1;
    }
    this.setState({
      height: (this.state.dwidth / divider) * (16 / 9),
    });
    if (this.state.dwidth !== vwidth) {
      this.setState({
        dwidth: $(window).width(),
        width: window.innerWidth / divider,
        height: (window.innerWidth / divider) * (16 / 9),
      });
      // console.log(window.innerWidth / divider);
      this.loadPosts();
    }
  };

  asignColors = () => {
    const categories = new Set();
    const results = [];
    const colors = ["orange", "blue", "pink", "yellow", "green", "red"];
    this.state.Posts.slice(0, this.state.Posts.length).map((post, i) => {
      return categories.add(post.post_category[0].cat_name);
    });
    //the set its transformed to an array for easier management and looping
    const catArray = Array.from(categories);
    catArray.slice(0, catArray.length).map((category, i) => {
      return results.push({
        category: category,
        color: colors[i],
      });
    });
    this.setState({ CategoriesColors: results });
    console.log(results);
  };

  loadPosts = async () => {
    this.setState({ Loading: true });
    const customPageNum = 1;
    const itemscount = 9;
    const category = "queer";
    const pageId = getPageId("queer");
    const self = this;
    setTimeout(function () {
      const content = self.getAdFunction(pageId);
      var div = document.createElement("span");
      div.innerHTML = content;

      $("#ads-rendered").html("");
      $("#ads-rendered").append(content);
    }, 100);
    await axios
      .get(
        `/get-post-by-category?page_no=${customPageNum}&itemscount=${itemscount}&category=${category}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        const numberOfPages = Math.ceil(res.data.data.length / 4);
        // console.log(res.data.data)
        const array = res.data.data.slice(0, numberOfPages);
        // console.log(array)
        const randomid2 = parseInt(Math.random() * 1000000000);
        this.setState({
          Posts: res.data.data,
          Loading: false,
          Pages: array,
          randomIdTopAd: randomid2,
          category: category,
        });
        this.asignColors();
        // console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
    //   .finally(() => {
    //     setTimeout(() => {
    //       this.cloneSlides();
    //     }, 1500);
    //   });
  };

  loadPostsMobile = async () => {
    this.setState({ Loading: true });
    const customPageNum = 1;
    const itemscount = 9;
    const category = "queer";
    await axios
      .get(
        `/get-post-by-category?page_no=${customPageNum}&itemscount=${itemscount}&category=${category}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        const numberOfPages = Math.ceil(res.data.data.length / 4);
        // console.log(res.data.data)
        const array = res.data.data.slice(0, numberOfPages);
        // console.log(array)
        const randomid2 = parseInt(Math.random() * 1000000000);
        this.setState({
          Posts: res.data.data,
          Loading: false,
          Pages: array,
          randomIdTopAd: randomid2,
        });
        this.asignColors();
        // console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAdFunction(pageId) {
    return;
    if (this.state.sasSetup) {
      return;
    }
    this.setState({ sasSetup: true });
    console.log("se ejecuta la funcion sas.setup");
    const adFunction = `
      <script>
        var sas = sas || {};
        sas.cmd = sas.cmd || [];
        sas.cmd.push(function() {
            sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
        });
        sas.cmd.push(function() {
            sas.call("onecall", {
                siteId: 391880,
                pageId: ${pageId},
                formats: [
                  { id: 119311 }
                  ,{ id: 119312 }
                  ,{ id: 119313 }
                  ,{ id: 119314 }
                  ,{ id: 119315 }
                  ,{ id: 119316 }
                  ,{ id: 119619 }
                  ,{ id: 119620 }
                  ,{ id: 119621 }
                  ,{ id: 119280 }
                ],
                target: ''
            });
        });
      </script>

      
    `;
    return adFunction;
  }

  render() {
    const { Loading, Posts, Pages, width, height, CategoriesColors } =
      this.state;
    return (
      <div>
        <div className="container queer">
          <div className="row">
            {!Loading ? (
              <>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <div className="row image-container">
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <img
                        className="img-fluid w-100 h-auto"
                        srcSet={Posts[0].imgs
                          .toReversed()
                          .map((image, index) => {
                            if (image[1] === undefined) {
                              return [];
                            }
                            const Srcset = [];
                            const Zize = image[1].toString();
                            const link = image[0].toString();
                            Srcset.push(link + " " + Zize + "w");
                            return Srcset;
                          })}
                        src={Posts[0].post_image.full_image_url}
                        alt={Posts[0].post_image.image_alt}
                      />
                    </div>
                    <div className="image-text">
                      <p>
                        <a
                          href="#"
                          className={
                            CategoriesColors.find(
                              (cat) =>
                                cat.category ===
                                Posts[0].post_category[0].cat_name
                            )?.color + " category"
                          }
                        >
                          {Posts[0].post_category[0].cat_name}
                        </a>
                      </p>

                      <a className="lh-initial">
                        <h2
                          className={
                            "line-clamp main-post-title border-" +
                            CategoriesColors.find(
                              (cat) =>
                                cat.category ===
                                Posts[0].post_category[0].cat_name
                            )?.color
                          }
                        >
                          {Posts[0].post_title !== null
                            ? Posts[0].post_title.length > 70
                              ? Posts[0].post_title.substr(0, 70) + `...`
                              : Posts[0].post_title
                            : ``}
                          {/* {Posts[0].post_title} */}
                        </h2>
                      </a>
                    </div>
                  </div>
                  {/* <div
                    className="row"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
                      <p className="author-link">
                        By{" "}
                        <a
                          href={`/author/${Posts[0].author_login}`}
                          className="author-name"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          {Posts[0].author_name}
                        </a>
                      </p>
                    </div>
                    <div className="col-xl-6 col-lg-8 col-md-6 col-sm-12 col-12">
                      <ul
                        class="nav nav-pills nav-fill no-scroll"
                        id="MainPostArtTags"
                      >
                        {!this.state.Loading ? (
                          <>{this.state.content2}</>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </div> */}
                  {/* <div
                    className="row"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <div className="col">
                      <p className="queer">{Posts[0].short_description}</p>
                      <div style={{ textAlign: "end" }}>
                        <Link
                          to={
                            Posts[0].post_type === "watch"
                              ? `/queer/watch/${Posts[0].post_slug}`
                              : `/queer/${Posts[0].post_slug}`
                          }
                          style={{ color: "#ED3897" }}
                        >
                          {" "}
                          READ MORE
                        </Link>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 side-scroll"
                  style={{ marginTop: "10px" }}
                >
                  <div className="row">
                    {Posts.slice(1, 9).map((post, index) => {
                      const {
                        ID,
                        post_category,
                        post_image,
                        post_title,
                        post_slug,
                        publish_date,
                        post_type,
                        imgs,
                        short_description,
                      } = post;
                      if (post_type === "post") {
                        return (
                          <div
                            className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12"
                            style={{ marginBottom: `20px` }}
                          >
                            <div
                              className="square-duo"
                              style={{ margin: "auto", position: "relative" }}
                            >
                              <Link to={`/queer/${post_slug}`}>
                                <div>
                                  <img
                                    sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                    srcSet={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Srcset = [];
                                        const Zize = image[1].toString();
                                        const link = image[0].toString();
                                        Srcset.push(link + " " + Zize + "w");
                                        return Srcset;
                                      })}
                                    src={post_image.medium_image_url}
                                    // cache
                                    className="img-fluid img-responsive w-100 "
                                    alt="alt"
                                  />
                                  <div className="image-text-second">
                                    <p>
                                      <a
                                        href="#"
                                        className={
                                          CategoriesColors.find(
                                            (cat) =>
                                              cat.category ===
                                              post.post_category[0].cat_name
                                          )?.color + " slide-category"
                                        }
                                      >
                                        {post.post_category[0].cat_name}
                                      </a>
                                    </p>

                                    <a className="lh-initial">
                                      <h2
                                        className={
                                          "line-clamp second-post-title border-" +
                                          CategoriesColors.find(
                                            (cat) =>
                                              cat.category ===
                                              post.post_category[0].cat_name
                                          )?.color
                                        }
                                      >
                                        {post_title !== null
                                          ? post_title.length > 70
                                            ? post_title.substr(0, 70) + `...`
                                            : post_title
                                          : ``}
                                        {/* {Posts[0].post_title} */}
                                      </h2>
                                    </a>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      } else if (post_type === "watch") {
                        return (
                          <div
                            className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12"
                            style={{ marginBottom: `20px` }}
                          >
                            <div className="square-duo">
                              <a href={`../watch/${post_slug}`}>
                                <div className="">
                                  <div
                                    className="side-video-thumbnail"
                                    style={{ maxWidth: `92%` }}
                                  >
                                    {/* <Img
                                      placeholder={LoadingPost}
                                      sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                      srcSet={imgs
                                        .toReversed()
                                        .map((image, index) => {
                                          if (image[1] === undefined) {
                                            return [];
                                          }
                                          const Srcset = [];
                                          const Zize = image[1].toString();
                                          const link = image[0].toString();
                                          Srcset.push(link + " " + Zize + "w");
                                          return Srcset;
                                        })}
                                      // src={post_image.medium_image_url}
                                      src={post_image.medium_image_url}
                                      loading="lazy"
                                      cache
                                      className="img-fluid w-100 h-auto"
                                      alt={post_image.image_alt}
                                    /> */}
                                    <div>
                                      {/* <img
                                        src={playbutton}
                                        className="img-fluid"
                                        alt={"play-button"}
                                      /> */}
                                    </div>
                                  </div>
                                  <div className="bottomRead-Content">
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      <h5>{post_title}</h5>
                                    </div>
                                  </div>
                                  <div className="bottom-content black-text line-clamp">
                                    <p className="queer">{short_description}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <VideoComponent />
        <SpecialsCarousel title={"Specials"} />
        <section id="ads-section" className="ads-section mt-0 mt-md-5 d-none">
          <div className="container-fluid" id="ads-rendered">
            {/* dangerouslySetInnerHTML={{ __html: content }}> */}
          </div>
        </section>
      </div>
    );
  }
}
export default QueerHomeComponent;
