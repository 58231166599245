import loadingSVG  from '../../images/loadingSVG.svg';

import React, { Component } from 'react'

export default class GridArticleComponent extends Component {
    render() {
        return (
            <>
                <div className="col">
                        <div className="card">
                            <img className="img-fluid w-100 image-placeholder placeholder" />
                            <div className="card-body pt-5">
                                <h6 className="card-title title-placeholder placeholder"></h6>
                            </div>
                        </div>
                    </div>
            </>
        )
    }
}