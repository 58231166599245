import React, { Component } from "react";
import $ from "jquery";
import axios from "../../apiConnections/axios";
// import SpecialsComponent from "./SpecialsComponent";

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.div = React.createRef();
    this.state = {
      Loading: true,
      LoadingSeries: true,
      Videos: [],
      activePage: 1,
      totalPages: 1,
      status: "start",
      matches: window.matchMedia("(min-width: 769px)").matches,
      hasSpecials: false,
    };
  }

  do_load = () => {
    var scriptJW = document.createElement("script");
    scriptJW.src = this.state.TrendingVideoSrc;
    scriptJW.defer = true;
    document.body.appendChild(scriptJW);
  };

  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  getScriptId(srcString) {
    const scriptId = srcString
      .substring(srcString.indexOf("rs/") + 3, srcString.lastIndexOf(".js"))
      .replaceAll(/-/gi, "_");

    return scriptId;
  }

  getVideoSrcId() {
    // var catTrend = !!this.props.state.category
    //   ? this.props.state.category
    //   : "queer";
    var catTrend = "queer";
    const categoryPrefix = "latv-";
    var apipath = `/get-pilar-playlist-by-slug?slug=${categoryPrefix}${catTrend}`;
    axios
      .get(`${apipath}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((resTrending) => {
        const scriptTag = this.createElementFromHTMLString(
          resTrending.data.data.post_content
        );
        const scriptSrc = scriptTag.src;
        //Get Id of script from src
        const scriptId = this.getScriptId(scriptSrc);
        // console.log(scriptId);
        this.setState({
          TrendingVideoId: scriptId,
          TrendingVideoSrc: scriptSrc,
        });
        const self = this;
        setTimeout(function () {
          const content = self.renderTopAd(119280);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119280_11111").html("");
          $("#bannerAd_sas_119280_11111").append(content);
        }, 100);
        setTimeout(function () {
          const content = self.renderTopAd(119619);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119619_11111").html("");
          $("#bannerAd_sas_119619_11111").append(content);
        }, 100);
        setTimeout(function () {
          const content = self.renderTopAd(119313);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119313_11111").html("");
          $("#bannerAd_sas_119313_11111").append(content);
        }, 100);
      });
  }

  componentDidMount() {
    this.loadVideos(this.state.activePage);
    this.loadSeries();
    // this.getVideoSrcId();
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
  }
  loadVideos = (pageNumber) => {
    axios
      .get(`/get-posts-by-tag?tag=latv-featured&itemscount=9`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({ Videos: res.data.data });
        this.setState({ Loading: false });
        this.getVideoSrcId();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadSeries = async () => {
    this.setState({ LoadingSeries: true });
    // const category = this.state.category;
    const category = "queer";
    await axios
      .get(`/get-special-pages?page_type=special&category=queer`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.data === false) {
          this.setState({ hasSpecials: false });
        } else {
          this.setState({ hasSpecials: true });
        }
        this.setState({ LoadingSeries: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePageChange(pageNumber) {
    this.loadVideos(pageNumber);
    this.setState({ Loading: true });
  }

  renderTopAd(adId) {
    const topAdFunction = `
    <script>sas.cmd.push(function() 
    {sas.call('std', {siteId:391880,pageId:1701583,formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
    `;
    return topAdFunction;
  }

  render() {
    const { Loading, Videos, TrendingVideoId } = this.state;
    let Bsize = "";
    var self = this;
    setTimeout(function () {
      $(document).on("scroll", function () {
        if ($(document).scrollTop() >= 500) {
          if (self.state.status === "start") {
            self.state.status = "loading";
            self.do_load();
          }
        }
      });
    }, 2000);

    return (
      <>
        <section id="video-section" className="video-section">
          <div className="container queer">
            <h2 className="titleNew">TRENDING VIDEOS</h2>
            <div className="row video-container">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div id={`botr_${TrendingVideoId}_div`}></div>
                <div className="auto-margin"></div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-0 col-sm-0 col-12">
                {/* <h2 className="titleNew">Specials</h2> */}
                <div className="video-ad-section">
                  <div>
                    {this.state.hasSpecials ? (
                      <>
                        {this.state.matches ? (
                          //   <div class="top-adverties video-container">
                          //     <SpecialsComponent section='queer' category='queer'/>
                          //     <div id="bannerAd_sas_119619_11111"></div>
                          //   </div>
                          <div class="top-adverties">
                            <div id="bannerAd_sas_119313_11111"></div>
                          </div>
                        ) : (
                          <div class="top-adverties video-container">
                            {/* <SpecialsComponent section='queer' category='queer'/> */}
                            <div
                              id="bannerAd_sas_119619_11111"
                              className="Ad119619"
                            ></div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div class="top-adverties video-container">
                        <div
                          id="bannerAd_sas_119313_11111"
                          className="Ad119619"
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default VideoComponent;
