import React, { Component } from 'react';
import axios from '../../apiConnections/axios'
import $ from 'jquery';
import { Link } from 'react-router-dom';
let flagApi = false;

class SpecialsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      category: this.props.category,
    };
  }

  componentDidMount() {
    if(!flagApi){
         this.loadSeries();
         flagApi = true;
    }
  }

  componentWillUnmount() {
    flagApi = false;
  }

  componentDidUpdate(prevProps) {

  }

  loadSeries = async () => {
    this.setState({ Loading: true });
    const category = this.state.category;
    await axios.get(`/get-special-pages?page_type=special&category=${category}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        this.setState({ Series: res.data });
        this.setState({ Loading: false });
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const { Loading, Series} = this.state;
    let contador = 0;
    let Bsize = '';
    return (
      <>
        { !!Series ? (
            <div id="specialsCarousel" className="carousel carousel-dark slide mt-4" data-bs-ride="carousel">
                <div className={`carousel-inner ${this.props.section === 'network' ? 'specials-network' : 'specials'}`} >
                    <>
                    {!Loading ? (
                        Series.slice(0, 1).map((post, i) => {
                        const { image, slug } = post;
                        return (
                            <div className="carousel-item active" key={i} >
                            <div className="col-12">
                                <Link to={`/${slug}`}>
                                <div className="card">
                                    <img className="img-fluid w-100 card-img-top ratioed"
                                        //src={post.image} 
                                        sizes={image.toReversed().map((image, index) => {
                                            if(image[1] === undefined){
                                            return []
                                            }
                                            const Size = ["(-webkit-min-device-pixel-ratio: 2) 50vw"];
                                            const size = image[1].toString();
                                            if(index === 0){
                                            Size.push("(min-width "+ size +"px) " + size + "w");
                                            Bsize = size - 1 ;
                                            }
                                            else{
                                            Size.push("(max-width "+ Bsize +"px) " + size + "w")
                                            Bsize = size - 1 ;
                                            }
                                            return Size
                                        } )}
                                        srcSet={image.toReversed().map((image, index) => {
                                            if(image[1] === undefined){
                                            return []
                                            }
                                            const Srcset = [];
                                            const Zize = image[1].toString();
                                            const link = image[0].toString();
                                            Srcset.push(link + " " + Zize + "w");
                                            return Srcset
                                        } )}
                                    alt={"LATV" + i} />
                                </div>
                                </Link>
                            </div>
                            </div>
                        );
                        })
                    ) : (
                        <>
                        </>
                        )}
                    {!Loading ? (
                        Series.slice(1, Series.length).map((post, i) => {
                        const { image, slug } = post;
                        return (
                            <div className="carousel-item" key={i} >
                            <div className="col-12">
                                <Link to={`/${slug}`}>
                                <div className="card">
                                    <img className="img-fluid w-100 card-img-top ratioed" 
                                    sizes={image.toReversed().map((image, index) => {
                                        if(image[1] === undefined){
                                        return []
                                        }
                                        const Size = ["(-webkit-min-device-pixel-ratio: 2) 50vw"];
                                        const size = image[1].toString();
                                        if(index === 0){
                                        Size.push("(min-width "+ size +"px) " + size + "w");
                                        Bsize = size - 1 ;
                                        }
                                        else{
                                        Size.push("(max-width "+ Bsize +"px) " + size + "w")
                                        Bsize = size - 1 ;
                                        }
                                        return Size
                                    } )}
                                    srcSet={image.toReversed().map((image, index) => {
                                        if(image[1] === undefined){
                                        return []
                                        }
                                        const Srcset = [];
                                        const Zize = image[1].toString();
                                        const link = image[0].toString();
                                        Srcset.push(link + " " + Zize + "w");
                                        return Srcset
                                    } )}
                                    alt={'LATV' + i} />
                                </div>
                                </Link>
                            </div>
                            </div>
                        );
                        })
                    ) : (
                        <>
                        </>
                        )}
                    </>
                </div>
                <a className="carousel-control-prev w-auto" href="#specialsCarousel" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next w-auto" href="#specialsCarousel" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            ) : (
            <>
            </>
        )}
      </>
    );

  }
}

export default SpecialsComponent;