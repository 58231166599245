import React, { Component } from "react";
import $ from "jquery";
import axios from "../../apiConnections/axios";
import { Link } from "react-router-dom";
import noPicture from "../../images/NoPicture.png";
import loadingSVG from "../../images/loadingSVG.svg";
import ImageResize from "../ImageResize/ImageResize.js";
import SideArticleComponent from "../SharedComponents/SideArticleComponent.js";
import SideArticles from "../SharedComponents/SideArticles.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
let flagApi = false;

class ReadSectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      activePage: 1,
    };
  }

  componentDidMount = () => {
    if (!flagApi) {
      this.loadPosts(this.state.activePage);
      flagApi = true;
    }
  };

  componentWillUnmount() {
    flagApi = false;
  }

  renderAd(adId) {
    const AdFunction = `
        <script>sas.cmd.push(function() 
        {sas.call('std', {siteId:391880,pageId:1743691,formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
        `;
    return AdFunction;
  }

  adAdds() {
    const self = this;
    setTimeout(function () {
      const content = self.renderAd(119621);
      var div = document.createElement("span");
      div.innerHTML = content;
      $("#bannerAd_sas_119621_11111").html("");
      $("#bannerAd_sas_119621_11111").append(content);
    }, 100);
    setTimeout(function () {
      const content = self.renderAd(119280);
      var div = document.createElement("span");
      div.innerHTML = content;
      $("#bannerAd_sas_119280_11111").html("");
      $("#bannerAd_sas_119280_11111").append(content);
    }, 100);
  }

  loadPosts = async (pageNumber) => {
    this.setState({ Loading: true });
    await axios
      .get(
        `/get-home-page-posts?page_no=${pageNumber}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          totalPages: res.data.pagecount,
          activePage: pageNumber,
          Posts: res.data.data,
          Loading: false,
        });
        this.adAdds();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let Bsize = "";
    const { Loading, Posts } = this.state;
    return (
      <div className="row mt-3">
        <div className="col-lg-8 col-md-8 col-sm-12 firstArticle">
          {!Loading ? (
            Posts.slice(0, 1).map((post) => {
              const {
                ID,
                author_name,
                post_category,
                short_description,
                post_slug,
                post_image,
                post_title,
                publish_date,
                imgs,
                author_login,
              } = post;
              return (
                <div key={ID}>
                  <Link to={`/${post_slug}`}>
                    <h1 data-bs-toggle="tooltip" title={post_title}>
                      {post_title !== null && post_title !== ""
                        ? post_title.length > 80
                          ? post_title.split(" ").slice(0, 69).join(" ") + `...`
                          : post_title
                        : ``}
                    </h1>
                  </Link>
                  {post_image.full_image_url !== null ? (
                    <>
                      <Link to={`/${post_slug}`}>
                        <ImageResize
                          state={this.state}
                          serie={post}
                          class="img-fluid w-100 card-img-top"
                          alter={post.serieName}
                        />
                      </Link>
                    </>
                  ) : (
                    <>
                      <img
                        className="img-fluid"
                        src="https://dummyimage.com/1920x1080/ff0000/fff.png&text=First+Article+Image"
                        alt=""
                      />
                    </>
                  )}
                  <div className="row mt-3">
                    <div className="main-read-heading clearfix">
                      <span className="main-read-heading-category-span">
                        {post_category[0] && post_category[0].cat_name !== null
                          ? post_category[0].cat_name
                          : `No Category`}
                      </span>
                      <span className="main-read-heading-author-span">By </span>
                      <Link to={`/author/${author_login}`}>
                        <span className="bio-link">{author_name}</span>
                      </Link>
                      <span className="main-read-heading-span">
                        {publish_date}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {short_description !== "" && short_description !== null ? (
                      <>
                        <div className="main-box-content">
                          <p>
                            {short_description.length > 400
                              ? short_description
                                  .split(" ")
                                  .slice(0, 40)
                                  .join(" ") + ` ...`
                              : short_description}
                          </p>
                        </div>
                        <Link to={`/${post_slug}`}>
                          <div className="main-box-footer">
                            READ MORE
                            <i className="fa fa-arrow-right d-none d-md-inline"></i>
                          </div>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div key={1}>
              <h1 className="title-placeholder placeholder"></h1>
              <img className="img-fluid image-placeholder placeholder" alt="" />
              <div className="row mt-3">
                <div className="main-box-content text-placeholder placeholder"></div>
              </div>
            </div>
          )}
          <div id="bannerAd_sas_119621_11111"></div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <h2>LATEST ARTICLES</h2>
          {/* {!Loading ? (
                        Posts.slice(1, 4).map(post => {
                            return(
                                <SideArticles post={post} state= {this.state}/>
                            )
                        })
                            ) : (
                                <>
                                    <SideArticleComponent />
                                    <SideArticleComponent />
                                    <SideArticleComponent />
                                </>
                        ) } */}
          <GridComponent2
            itemsCount={3}
            state={this.state}
            columns={1}
            pagination={false}
            excerpt={false}
            loadType="read"
            pageNumber="1"
            Posts={Posts}
            Loading={this.state.Loading}
          />
          <div id="bannerAd_sas_119280_11111"></div>
        </div>
      </div>
    );
  }
}

export default ReadSectionComponent;
