import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import React, { Component, Suspense, useState, useEffect } from "react";
import Header from "./components/Header/Header";
import HomeComponent from "./components/Home/HomeComponent";
import Footer from "./components/Footer/Footer";
import SReadComponent from "./components/SingleArticle/SReadComponent";
import axios from "./apiConnections/axios";
import CategoryTemporal from "./components/Category/CategoryTemporal";
import SearchPostsComponent from "./components/Search/SearchPostsComponent";
import QueerHomeComponent from "./components/Queer/QueerHomeComponent";
import HeaderQueer from "./components/Header/HeaderQueer";
let flagApi = false;
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();

function App() {
  const location = useLocation();
  console.log(location.pathname);
  const [loading, setLoading] = useState(true);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [secondaryMenu, setSecondaryMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState([]);

  useEffect(() => {
    loadMenus();
  }, []);

  const loadMenus = async () => {
    try {
      const response = await axios.get(
        `/get-menus?timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );
      console.log(response);
      setHeaderMenu(response.data.menus.header_menu);
      setSecondaryMenu(response.data.menus.secondary_menu);
      setFooterMenu(response.data.menus.footer_menu);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!loading && (
        <>
          {!location.pathname.startsWith("/queer") ? (
            <Header urls={{}} menu={headerMenu} secondaryMenu={secondaryMenu} />
          ) : (
            <></>
          )}
          {location.pathname.startsWith("/queer") ? (
            <HeaderQueer
              location={location}
              urls={{}}
              menu={headerMenu}
              secondaryMenu={secondaryMenu}
            />
          ) : (
            <></>
          )}
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route
              exact
              path="/search/:slug"
              element={<SearchPostsComponent urls={{}} />}
            />
            <Route
              exact
              path={"/culture"}
              element={<CategoryTemporal urls={{}} category="culture" />}
            />
            <Route
              exact
              path={"/latina"}
              element={<CategoryTemporal urls={{}} category="latina" />}
            />
            <Route
              exact
              path={"/afrolatino"}
              element={<CategoryTemporal urls={{}} category="afrolatino" />}
            />
            <Route
              exact
              path={"/queer"}
              element={<QueerHomeComponent urls={{}} category="queer" />}
            />
            <Route path="/:slug" element={<SReadComponent urls={{}} />} />
          </Routes>
          <Footer footerMenu={footerMenu} />
        </>
      )}
    </>
  );
}

export default App;

// class App extends Component {
//   state = {
//     headerMenu: [],
//     secondaryMenu: [],
//     footerMenu: [],
//     loading: true,
//   };

//   componentDidMount() {
//     if (!flagApi) {
//       this.loadMenus();
//       flagApi = true;
//     }
//   }

//   loadMenus = async () => {
//     try {
//       const response = await axios.get(
//         `/get-menus?timestamp=${new Date().getTime()}`,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json;charset=UTF-8",
//           },
//         }
//       );
//       console.log(response);
//       this.setState({
//         loading: false,
//         headerMenu: response.data.menus.header_menu,
//         secondaryMenu: response.data.menus.secondary_menu,
//         footerMenu: response.data.menus.footer_menu,
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   render() {
//     return (
//       <>
//         <Router>
//           {!this.state.loading ? (
//             <Header
//               urls={this.state}
//               menu={this.state.headerMenu}
//               secondaryMenu={this.state.secondaryMenu}
//             />
//           ) : (
//             <></>
//           )}
//           <Routes>
//             <Route
//               exact
//               path="/"
//               element={
//                 <>
//                   <HomeComponent />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path="/search/:slug"
//               element={
//                 <>
//                   <SearchPostsComponent urls={this.state} />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/culture"}
//               element={
//                 <>
//                   <CategoryTemporal urls={this.state} category="culture" />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/latina"}
//               element={
//                 <>
//                   <CategoryTemporal urls={this.state} category="latina" />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/afrolatino"}
//               element={
//                 <>
//                   <CategoryTemporal urls={this.state} category="afrolatino" />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/queer"}
//               element={
//                 <>
//                   <QueerHomeComponent urls={this.state} category="queer" />
//                 </>
//               }
//             />
//             <Route
//               path={"/:slug"}
//               element={
//                 <>
//                   <SReadComponent urls={this.state} />
//                 </>
//               }
//             />
//           </Routes>
//           {!this.state.loading ? (
//             <Footer footerMenu={this.state.footerMenu} />
//           ) : (
//             <></>
//           )}
//         </Router>
//       </>
//     );
//   }
// }

// export default App;
