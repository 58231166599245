export const getAdFunction = (pageId) => {
  const adFunction = `
      <script>
        var sas = sas || {};
        sas.cmd = sas.cmd || [];
        sas.cmd.push(function() {
            sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
        });
        sas.cmd.push(function() {
            sas.call("onecall", {
                siteId: 391880,
                pageId: ${pageId},
                formats: [
                  { id: 119311 }
                  ,{ id: 119312 }
                  ,{ id: 119313 }
                  ,{ id: 119314 }
                  ,{ id: 119620 }
                ],
                target: ''
            });
        });
      </script>
    `;
  return adFunction;
};
