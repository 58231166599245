import React, { Component } from "react";
import $ from "jquery";
import axios from "../../apiConnections/axios.js";
import { useParams } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import ImageResize from "../ImageResize/ImageResize.js";
import SpecialsComponent from "../SharedComponents/SpeciaslComponent.js";
import InfiniteScroll from "react-infinite-scroll-component";
import loadingSVG from "../../images/loadingSVG.svg";
import GridArticleComponent from "../SharedComponents/GridArticleComponent.js";
import GridComponent from "../SharedComponents/GridComponent.js";
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import { getAdFunction } from "../Ads/AdsFunctions/getAdFunction.js";
import { renderAd } from "../Ads/AdsFunctions/renderAd.js";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import TrendingVideo from "../SharedComponents/TrendingVideo.js";
import MailchimpFormComponent from "../SharedComponents/MailchimpFormComponent.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
import "../../styles/ads-default.css";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class SReadComponent extends Component {
  insta_token =
    "IGQWRQWlNCMTB6bnlOQkc5NG5iMGZA3OU81b2lYa0dNVmlFb09neHZAMVlp0T1VrR2NhN3diMTBsckp6UzRuQTZAfMU9XOVNSNkRxVExFTjczdGg0aGZAUTkdnQnpkWlVhNzBRMmNNSDlCSDV2b0VBVXlNRVBabVFFUjgZD";
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      SiteId: "391880",
      PageId: 0,
      Loading: true,
      LoadingAll: true,
      Post: [],
      LatestPosts: [],
      ReletedPaginatioPost: [],
      pageNumber: 1,
      searchSlug: "",
      status: "start",
      hasMore: true,
      mainPostId: "",
      mainPostCategory: "",
      mainPostCategorySlug: "",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
      totalPages: 2,
      reletedGridData: [],
      visibility: false,
      randomID: "",
      GlobalsasDynamiccount: 0,
      GlobalstartDate: "",
      GlobalAdsRefreshCount: 0,
      GlobalScrollAdsIds: [],
      TrendingVideoId: "acKr2ukJ_owsYDZVC",
      TrendingVideoSrc:
        "https://cdn.jwplayer.com/players/acKr2ukJ-owsYDZVC.js?search=__CONTEXTUAL__",
      igScript: '<script async src="//www.instagram.com/embed.js"></script>',
      twScript:
        '<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>',
      ptScript:
        '<script async defer src="//assets.pinterest.com/js/pinit.js"></script>',
      hasSpecials: false,
    };
  }

  do_load = () => {
    //Trending Video script
    var scriptTrendingVid = document.createElement("script");
    scriptTrendingVid.src = this.state.TrendingVideoSrc;
    scriptTrendingVid.defer = true;
    document.body.appendChild(scriptTrendingVid);
    // const { Post } = this.state;
    // const scriptArray = Post?.post_content_scriptJWP_array ?? [];
    // scriptArray.forEach((scriptString) => {
    //   this.appendScriptElementFromString(scriptString);
    // });
  };

  afterFirstPost() {
    // console.log("afterfirtspost")
    const mainPost = this.state.Post;
    if (
      mainPost.post_title !== "" &&
      mainPost.post_title !== null &&
      mainPost.post_title !== "undefined"
    ) {
      var MyDiv = document.getElementById("content-rendered");
      // console.log(MyDiv)
      if (MyDiv !== null) {
        // console.log("entra a mydiv")
        var arr = MyDiv.getElementsByTagName("script");
        var arrIframe = MyDiv.getElementsByTagName("iframe");
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].src.includes("https://cdn.jwplayer.com/players/")) {
            // console.log("includes script?")
            var filename = arr[n].src
              .substring(arr[n].src.lastIndexOf("/") + 1)
              .split(".");
            if (filename && filename !== undefined) {
              const querySelector =
                'p script[src*="https://cdn.jwplayer.com/players/"]';
              const scriptTags = document.querySelectorAll(querySelector);
              scriptTags.forEach((scriptTag) => {
                const s = new XMLSerializer();
                const newScriptTag = s.serializeToString(scriptTag);
                this.appendScriptElementFromString(newScriptTag);
              });
            }
          }
        }
        for (var m = 0; m < arrIframe.length; m++) {
          if (arrIframe[m].src.includes("https://cdn.jwplayer.com/players/")) {
            // console.log("includes player")
            var filename2 = arrIframe[m].src
              .substring(arrIframe[m].src.lastIndexOf("/") + 1)
              .split(".");
            const filenameCero = filename2[0];
            if (filename2 && filename2 !== undefined) {
              const querySelector =
                'div iframe[src*="https://cdn.jwplayer.com/players/"]';
              const iframetTags = document.querySelectorAll(querySelector);
              // console.log(iframetTags)
              iframetTags.forEach((iframeTag) => {
                // console.log(iframeTag);
                // const sriptSrc = "https://cdn.jwplayer.com/players/" + filenameCero + ".html";
                // const script = document.createElement('script');
                // script.src = sriptSrc;
                // console.log(script);
                // const s = new XMLSerializer();
                // const newScriptTag = s.serializeToString(script)
                // this.appendScriptElementFromStringIframe(newScriptTag);
                const sriptSrc =
                  "https://cdn.jwplayer.com/players/" + filenameCero + ".js";
                const script = document.createElement("script");
                script.src = sriptSrc;
                // Replace the script tag with the container div
                iframeTag.parentNode.replaceWith(script);
                var arr = MyDiv.getElementsByTagName("script");
                // console.log(arrIframe)
                for (var n = 0; n < arr.length; n++) {
                  if (
                    arr[n].src.includes("https://cdn.jwplayer.com/players/")
                  ) {
                    // console.log("includes script?")
                    var filename = arr[n].src
                      .substring(arr[n].src.lastIndexOf("/") + 1)
                      .split(".");
                    if (filename && filename !== undefined) {
                      const querySelector =
                        'script[src*="https://cdn.jwplayer.com/players/"]';
                      const scriptTags =
                        document.querySelectorAll(querySelector);
                      scriptTags.forEach((scriptTag) => {
                        const s = new XMLSerializer();
                        const newScriptTag = s.serializeToString(scriptTag);
                        this.appendScriptElementFromString(newScriptTag);
                      });
                    }
                  }
                }
              });
            }
          }
        }
      }
    } else {
      $("#content-rendered").html("");
    }
  }

  afterFollowingPost(currentPostArr) {
    let currentPost = currentPostArr[0];
    console.log(currentPost.ID);
    var div = document.createElement("span");
    div.innerHTML = currentPost.post_content;
    if (
      currentPost.post_title !== "" &&
      currentPost.post_title !== null &&
      currentPost.post_title !== "undefined"
    ) {
      var MyDiv = document.getElementById(currentPost.ID);
      if (MyDiv !== null) {
        var arr = MyDiv.getElementsByTagName("script");
        var arrIframe = MyDiv.getElementsByTagName("iframe");
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].src.includes("https://cdn.jwplayer.com/players/")) {
            var filename = arr[n].src
              .substring(arr[n].src.lastIndexOf("/") + 1)
              .split(".");
            if (filename && filename !== undefined) {
              const querySelector =
                'p script[src*="https://cdn.jwplayer.com/players/"]';
              const scriptTags = document.querySelectorAll(querySelector);
              scriptTags.forEach((scriptTag) => {
                const s = new XMLSerializer();
                const newScriptTag = s.serializeToString(scriptTag);
                this.appendScriptElementFromString(newScriptTag);
              });
            }
          }
        }
        for (var m = 0; m < arrIframe.length; m++) {
          if (arrIframe[m].src.includes("https://cdn.jwplayer.com/players/")) {
            console.log("includes player");
            var filename2 = arrIframe[m].src
              .substring(arrIframe[m].src.lastIndexOf("/") + 1)
              .split(".");
            const filenameCero = filename2[0];
            if (filename2 && filename2 !== undefined) {
              const querySelector =
                'div iframe[src*="https://cdn.jwplayer.com/players/"]';
              const iframetTags = document.querySelectorAll(querySelector);
              console.log(iframetTags);
              iframetTags.forEach((iframeTag) => {
                // console.log(iframeTag);
                // const sriptSrc = "https://cdn.jwplayer.com/players/" + filenameCero + ".html";
                // const script = document.createElement('script');
                // script.src = sriptSrc;
                // console.log(script);
                // const s = new XMLSerializer();
                // const newScriptTag = s.serializeToString(script)
                // this.appendScriptElementFromStringIframe(newScriptTag);
                const sriptSrc =
                  "https://cdn.jwplayer.com/players/" + filenameCero + ".js";
                const script = document.createElement("script");
                script.src = sriptSrc;
                // Replace the script tag with the container div
                iframeTag.parentNode.replaceWith(script);
                var arr = MyDiv.getElementsByTagName("script");
                console.log(arrIframe);
                for (var n = 0; n < arr.length; n++) {
                  if (
                    arr[n].src.includes("https://cdn.jwplayer.com/players/")
                  ) {
                    console.log("includes script?");
                    var filename = arr[n].src
                      .substring(arr[n].src.lastIndexOf("/") + 1)
                      .split(".");
                    if (filename && filename !== undefined) {
                      const querySelector =
                        'script[src*="https://cdn.jwplayer.com/players/"]';
                      const scriptTags =
                        document.querySelectorAll(querySelector);
                      scriptTags.forEach((scriptTag) => {
                        const s = new XMLSerializer();
                        const newScriptTag = s.serializeToString(scriptTag);
                        this.appendScriptElementFromString(newScriptTag);
                      });
                    }
                  }
                }
              });
            }
          }
        }
      }
    } else {
      $("#content-rendered").html("");
    }
  }

  appendScriptElementFromString(scriptString) {
    const scriptTag = this.createElementFromHTMLString(scriptString);
    const scriptSrc = scriptTag.src;
    //Get Id of script from src
    const scriptId = this.getScriptId(scriptSrc);

    const scriptDom = $(`script[src*="${scriptSrc}"]`);
    var divJW = document.createElement("div");
    divJW.id = `botr_${scriptId}_div`;
    scriptDom.replaceWith(divJW);

    var scriptJW = document.createElement("script");
    scriptJW.src = scriptSrc;
    scriptJW.defer = true;
    document.body.appendChild(scriptJW);
  }

  appendTiktokScript(url, isRemove = false) {
    if (isRemove) {
      var tkJs = $(`script[src*="${url}"]`);
      tkJs.remove();
    }
    const scriptTtkTag = this.createElementFromHTMLString(
      `<script async src="${url}"></script>`
    );
    const scriptTtkSrc = scriptTtkTag.src;
    const scriptTtkDom = $(`script[src*="${scriptTtkSrc}"]`);
    var divTtk = document.createElement("div");
    scriptTtkDom.replaceWith(divTtk);
    var scriptTtk = document.createElement("script");
    scriptTtk.src = scriptTtkSrc;
    scriptTtk.defer = true;
    document.body.appendChild(scriptTtk);
  }

  getScriptId(srcString) {
    const scriptId = srcString
      .substring(srcString.indexOf("rs/") + 3, srcString.lastIndexOf(".js"))
      .replaceAll(/-/gi, "_");

    return scriptId;
  }

  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  createScriptFromUrl(url) {
    return `<script src="${url}" defer></script>`;
  }

  createDivElementFromId(scriptId) {
    var divJW = document.createElement("div");
    divJW.id = `botr_${scriptId}_div`;
    return divJW;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());
    scriptLotameTag.defer = true;
    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    $("#lotameTag").append(scriptLotameTag);
  }

  lotameLoad() {
    let path_name = window.location.pathname;
    let url = window.location.href;
    let article_tags = [];
    let article_cats = [];
    let int = [
      `author: ${this.state.articleAuthor}`,
      `main category: ${this.state.mainPostCategorySlug}`,
    ];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    this.state.subCategories.forEach((s) => {
      int.push(s);
    });
    // console.log(int)
    let data = {
      behaviors: {
        int: int,
        med: [`article category : ${this.state.mainPostCategorySlug}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
          <script type="text/javascript">
            window.lotame_16314.cmd.push(function() {
              window.lotame_16314.page(${dataString});
            });
          </script>
        `;
    return lotameFunction;
  }

  componentDidMount() {
    this.loadPosts();
    window.scrollTo(0, 0);
    var self = this;
    setTimeout(function () {
      self.do_load();
    }, 2000);
  }

  loadPosts = async () => {
    const categories = ["culture", "lgbtq", "latina", "afrolatino"];
    const isCategory = categories.includes(this.props.params.slug);

    if (isCategory === true) {
    } else {
      await axios
        .get(
          `/get-post-by-slug2?slug=${
            this.props.params.slug
          }&timestamp=${new Date().getTime()}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then(async (res) => {
          console.log(res);
          if (res.data.code === 0 && res.data.data.post_type === "page") {
            window.location.href = `../pages/${this.props.params.slug}`;
          }
          if (
            res.data.data.ads_cat.cat_slug === "queer" ||
            !!res.data.data.post_category.find(
              (category) => category.cat_name === "QUEER"
            )
          ) {
            window.location.href = `../queer/${this.props.params.slug}`;
          }
          let post_cat_slug = res.data.data.ads_cat.cat_slug;
          let post_cat_name = res.data.data.ads_cat.cat_name;
          let subcategorias = [];
          res.data.data.post_category.forEach((e) => {
            subcategorias.push(e.cat_slug);
          });
          if (!post_cat_slug) {
            post_cat_slug = res.data.data.post_category[0].cat_slug;
            post_cat_name = res.data.data.post_category[0].cat_name;
          }
          const pageId = getPageId(post_cat_slug);
          this.setState({
            Post: res.data.data,
            mainPostId: res.data.data.ID,
            mainPostCategory: post_cat_name,
            mainPostCategorySlug: post_cat_slug,
            articleTags: res.data.data.post_tags,
            subCategories: subcategorias,
            articleTitle: res.data.data.post_title,
            articleAuthor: res.data.data.author_name,
            PageId: pageId,
          });
          this.loadSeries();
          setTimeout(function () {
            self.afterFirstPost();
          }, 1000);
          const foundTw =
            res.data.data.post_content?.indexOf("twitter-tweet") !== -1;
          const foundIg =
            res.data.data.post_content?.indexOf("instagram-media") !== -1;
          let hasTriggeredIg;
          let hasTriggeredTw;
          if (foundIg && !hasTriggeredIg) {
            console.log("hizo el append Ig");
            this.appendScriptElementFromString(this.state.igScript);
            setTimeout(function () {
              window.instgrm.Embeds.process();
            }, 500);
            hasTriggeredIg = true;
          }
          if (foundTw && !hasTriggeredTw) {
            console.log("hizo el append Tw");
            this.appendScriptElementFromString(this.state.twScript);
            setTimeout(function () {
              window.twttr.widgets.load();
            }, 500);
            hasTriggeredTw = true;
          }

          const categoryPrefix1 = "latv-";
          const categoryPrefix2 = "latv ";
          const extraCategories = ["queer", "roots"];
          const tagCategories = categories.concat(extraCategories);
          const categoriesWithPrefix1 = tagCategories.map(
            (category) => `${categoryPrefix1}${category}`
          );
          const categoriesWithPrefix2 = tagCategories.map(
            (category) => `${categoryPrefix2}${category}`
          );
          const categoriesWithPrefix = categoriesWithPrefix1.concat(
            categoriesWithPrefix2
          );
          var videoTag = null;
          res.data.data.post_tags.map((tag) => {
            const iDx = categoriesWithPrefix.indexOf(tag);
            if (iDx > -1) {
              videoTag = tag
                .replace("queer", "lgbtq")
                .replace("roots", "afrolatino")
                .replaceAll(" ", "-");
            }
            return null;
          });

          if (videoTag) {
            var apipath = `/get-pilar-playlist-by-slug?slug=${videoTag}`;
            await axios
              .get(`${apipath}`, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                },
              })
              .then((resTrending) => {
                const scriptTag = this.createElementFromHTMLString(
                  resTrending.data.data.post_content
                );
                const scriptSrc = scriptTag.src;
                //Get Id of script from src
                const scriptId = this.getScriptId(scriptSrc);
                this.setState({
                  TrendingVideoId: scriptId,
                  TrendingVideoSrc: scriptSrc,
                  Loading: false,
                });
              })
              .catch((error) => {
                console.log(error);
                this.setState({ Loading: false });
              });
          } else {
            this.setState({ Loading: false });
          }

          this.loadLatestPosts(res.data.data.ID);
          this.appendLotameScript();
          const self = this;
          setTimeout(function () {
            const content = getAdFunction(pageId);
            var div = document.createElement("span");
            div.innerHTML = content;

            $("#ads-rendered").html("");
            $("#ads-rendered").append(content);
          }, 100);
          setTimeout(function () {
            const content = renderAd(119314, pageId);
            var div = document.createElement("span");
            div.innerHTML = content;
            $("#bannerAd_sas_119314_11111").html("");
            $("#bannerAd_sas_119314_11111").append(content);
          }, 100);
          setTimeout(function () {
            const content = renderAd(119620, pageId);
            var div = document.createElement("span");
            div.innerHTML = content;
            $("#bannerAd_sas_119620_11111").html("");
            $("#bannerAd_sas_119620_11111").append(content);
          }, 100);
          setTimeout(function () {
            const content = renderAd(119313, pageId);
            var div = document.createElement("span");
            div.innerHTML = content;
            $("#bannerAd_sas_119313_11111").html("");
            $("#bannerAd_sas_119313_11111").append(content);
          }, 100);
          setTimeout(function () {
            const content = renderAd(119311, pageId);
            var div = document.createElement("span");
            div.innerHTML = content;
            $("#bannerAd_sas_119311_11111").html("");
            $("#bannerAd_sas_119311_11111").append(content);
          }, 100);
          setTimeout(function () {
            const content = renderAd(119312, pageId);
            var div = document.createElement("span");
            div.innerHTML = content;
            $("#bannerAd_sas_119312_11111").html("");
            $("#bannerAd_sas_119312_11111").append(content);
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  loadSeries = async () => {
    this.setState({ LoadingSeries: true });
    const category = this.state.mainPostCategory;
    await axios
      .get(`/get-special-pages?page_type=special&category=${category}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.data === false) {
          this.setState({ hasSpecials: false });
        } else {
          this.setState({ hasSpecials: true });
        }
        this.setState({ LoadingSeries: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadLatestPosts = async (PostID) => {
    await axios
      .get(
        `/get-releted-post-from-contextly/?PostId=${PostID}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({ LatestPosts: res.data.data });
        this.setState({ LoadingAll: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  appendAdsToPost(post) {
    const pageId = this.state.PageId;
    const siteId = this.state.SiteId;

    const top_content_formats = [];
    const bot_content_formats = ["119620"];
    const side_formats = ["119313", "119311", "119312"];

    top_content_formats.forEach((format) => {
      this.appendAd(post.randDivIdTop, format, pageId, siteId, "top");
    });
    bot_content_formats.forEach((format) => {
      this.appendAd(post.randDivIdBot, format, pageId, siteId, "bot");
    });
    side_formats.forEach((format) => {
      this.appendAd(post.randDivIdSide, format, pageId, siteId, "side");
    });
  }

  //Type can be top/bot/side
  appendAd(divId, format, pageId, siteId, type) {
    var rndId = parseInt(Math.random() * 1000000);
    rndId = `sas_${format}_${rndId}`;
    var art = document.createElement("div");
    $(art).addClass(`${type}-adverties`);
    // Create a container in the article with the SMART tag
    var smartDiv = document.createElement("div");
    smartDiv.id = rndId;
    var smartTag = document.createElement("script");
    $(smartTag).text(
      `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:${rndId}});});`
    );
    art.appendChild(smartDiv);
    art.appendChild(smartTag);
    $("#" + divId).append(art);

    // currentAdsIds.push(rndId);

    var refreshContentSc = document.createElement("script");
    $(refreshContentSc).text(
      "localStorage.setItem('startDate" +
        rndId +
        "', 'start'); localStorage.setItem('temp" +
        rndId +
        "', '" +
        rndId +
        "'); localStorage.setItem('tempcount" +
        rndId +
        "', 0);   setInterval(function(){var countsAds=0; var top_of_element = $('#" +
        rndId +
        "').offset().top; var bottom_of_element = $('#" +
        rndId +
        "').offset().top + $('#" +
        rndId +
        "').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate" +
        rndId +
        "'); if(lastsyncdata=='start'){ localStorage.setItem('startDate" +
        rndId +
        "', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate" +
        rndId +
        "')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount" +
        rndId +
        "');  var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp" +
        rndId +
        "');   if(loopCount<5 && Diffseconds>30){ sas.cmd.push(function() {sas.refresh(" +
        rndId +
        ");}); localStorage.setItem('tempcount" +
        rndId +
        "', (Number(localStorage.getItem('tempcount" +
        rndId +
        "'))+1)); localStorage.setItem('startDate" +
        rndId +
        "', new Date()); }}},1000)"
    );
    $("#" + divId).append(refreshContentSc);
  }

  loadReletedPaginatioPosts = async (category) => {
    if (this.state.totalPages < this.state.pageNumber) {
      this.setState({ hasMore: false });
      return;
    }
    var customCat = this.state.mainPostCategorySlug;
    var mainPostId = this.state.mainPostId;
    var pageNumber = this.state.pageNumber;
    console.log(customCat, mainPostId, pageNumber);
    await axios
      .get(
        `/get-full-post-by-category/?category=${customCat}&mainPostId=${mainPostId}&page_no=${pageNumber}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        let currentPost = res.data.data;
        //Initialize div Ids
        const paginationPosts = [].concat(res.data.data);
        paginationPosts.forEach((post) => {
          post.randDivIdTop = parseInt(Math.random() * 1000000000);
          post.randDivIdBot = parseInt(Math.random() * 1000000000);
          post.randDivIdSide = parseInt(Math.random() * 1000000000);
        });
        this.setState({
          ReletedPaginatioPost:
            this.state.ReletedPaginatioPost.concat(paginationPosts),
          pageNumber: pageNumber + 1,
          totalPages: res.data.pagecount,
        });
        const self = this;
        setTimeout(function () {
          self.afterFollowingPost(currentPost);
        }, 1000);
        const foundTw =
          res.data.data.post_content?.indexOf("twitter-tweet") !== -1;
        const foundIg =
          res.data.data.post_content?.indexOf("instagram-media") !== -1;
        const foundTiktok =
          res.data.data.post_content?.indexOf("tiktok-embed") !== -1;
        let hasTriggeredIg;
        let hasTriggeredTw;
        let hasTriggeredTiktok;
        if (foundIg && !hasTriggeredIg) {
          this.appendScriptElementFromString(this.state.igScript);
          setTimeout(function () {
            window.instgrm.Embeds.process();
          }, 1500);
          hasTriggeredIg = true;
        }
        // if (foundTw && !hasTriggeredTw) {
        //   this.appendTwitterScript(this.state.twScript);
        //   setTimeout(function () {
        //     window.twttr.widgets.load();
        //   }, 1500);
        //   hasTriggeredTw = true;
        // }
        // if (foundTiktok && !hasTriggeredTiktok) {
        //   console.log("hizo el append Tiktok");
        //   this.this.appendTiktokScript("https://www.tiktok.com/embed.js");
        //   hasTriggeredTiktok = true;
        // }
        this.appendAdsToPost(paginationPosts[0]);

        var GoogleAnalyticsRequest = document.createElement("script");
        $(GoogleAnalyticsRequest).text(
          "gtag('event','page_view',{'page_title':'" +
            paginationPosts[0].post_title +
            "','page_location':'https://latv.com/" +
            paginationPosts[0].post_slug +
            "','page_path':'/" +
            paginationPosts[0].post_slug +
            "'});"
        );
        $("#" + paginationPosts[0].randDivIdSide).append(
          GoogleAnalyticsRequest
        );
      })
      .catch((error) => {
        console.log(error);
      });
    this.appendTiktokScript("https://www.tiktok.com/embed.js", true);
  };

  render() {
    const {
      Loading,
      Post,
      LatestPosts,
      TrendingVideoId,
      mainPostCategorySlug,
    } = this.state;
    const shareUrl = this.props.urls.site_url + this.props.urls.site_postfix;
    // setTimeout(function () {
    //   var div = document.createElement("span");
    //   div.innerHTML = Post.post_content;
    //   if (
    //     Post.post_title !== "" &&
    //     Post.post_title !== null &&
    //     Post.post_title !== "undefined"
    //   ) {
    //     $("#content-rendered").html("");
    //     $("#content-rendered").append(Post.post_content);
    //   } else {
    //     $("#content-rendered").html("");
    //     console.log($("#content-rendered"));
    //   }
    // }, 5500);
    return (
      <section id="read-section" className="read-section">
        <div className="container">
          {!Loading ? (
            <>
              <div className="row">
                <>
                  <div
                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 singleRfirstArticle"
                    key={Post.ID}
                  >
                    <h1
                      className="super-heading"
                      data-toggle="tooltip"
                      title={Post.post_title}
                    >
                      {Post.post_title !== null && Post.post_title !== ""
                        ? Post.post_title.length > 120
                          ? Post.post_title.substr(0, 120) + `...`
                          : Post.post_title
                        : ``}
                    </h1>
                    <div className="main-img-box">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <ImageResize
                            state={this.state}
                            serie={Post}
                            className="img-fluid w-100 card-img-top"
                            alter={Post.post_image.image_alt}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="main-read-heading clearfix">
                          <span className="main-read-heading-span d-block">
                            {Post.publish_date}
                          </span>
                          <span className="main-read-heading-category-span">
                            {Post.post_category[0] &&
                            Post.post_category[0].cat_name !== null
                              ? Post.post_category[0].cat_name
                              : ``}
                          </span>
                          <span className="main-read-heading-author-span">
                            By{" "}
                          </span>
                          <Link to={`/author/${Post.author_login}`}>
                            <span className="main-read-heading-author-span bio-link">
                              {Post.author_name}
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="main-box-content" id="content-rendered">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: Post.post_content,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="social-sharing social-container">
                          <FacebookShareButton
                            url={`${shareUrl}/${this.props.params.slug}`}
                            quote={"LATV - " + Post.post_title}
                            hashtag={
                              Post.post_category[0] &&
                              Post.post_category[0].cat_name !== null
                                ? `#LATV-${Post.post_category[0].cat_name}`
                                : `#LATV-${Post.author_name}`
                            }
                            className={"social-btn"}
                          >
                            <FacebookIcon size={32} />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={`${shareUrl}/${this.props.params.slug}`}
                            quote={"LATV -" + Post.post_title}
                            hashtag={
                              Post.post_category[0] &&
                              Post.post_category[0].cat_name !== null
                                ? `#LATV-${Post.post_category[0].cat_name}`
                                : `#LATV-${Post.author_name}`
                            }
                            className={"social-btn"}
                          >
                            <TwitterIcon size={32} />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={`${shareUrl}/${this.props.params.slug}`}
                            quote={"LATV -" + Post.post_title}
                            hashtag={
                              Post.post_category[0] &&
                              Post.post_category[0].cat_name !== null
                                ? `#LATV-${Post.post_category[0].cat_name}`
                                : `#LATV-${Post.author_name}`
                            }
                            className={"social-btn"}
                          >
                            <LinkedinIcon size={32} />
                          </LinkedinShareButton>
                          <EmailShareButton
                            url={`${shareUrl}/${this.props.params.slug}`}
                            quote={"LATV -" + Post.post_title}
                            hashtag={
                              Post.post_category[0] &&
                              Post.post_category[0].cat_name !== null
                                ? `#LATV-${Post.post_category[0].cat_name}`
                                : `#LATV-${Post.author_name}`
                            }
                            className={"social-btn"}
                          >
                            <EmailIcon size={32} />
                          </EmailShareButton>
                        </div>
                      </div>
                    </div>
                    <h4 className="side-heading mt-4 mb-2">
                      {LatestPosts.length >= 1 ? `RELATED ARTICLES` : ``}
                    </h4>
                    <div className="row">
                      <GridComponent2
                        itemsCount={3}
                        state={this.state}
                        columns={3}
                        pagination={false}
                        excerpt={true}
                        pageNumber="1"
                        Posts={LatestPosts}
                        Loading={this.state.Loading}
                      />
                      {/* {(
                          LatestPosts.slice(0, 3).map(post => {
                            const {post_slug} = post;
                            return (
                              ((post_slug !== Post.post_slug)) ? 
                                <GridComponent post={post} state={this.state}/>
                              : <>
                                  <GridArticleComponent/>
                                  <GridArticleComponent/>
                                  <GridArticleComponent/>
                                </>
                            );
                          }) )} */}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="side-video-box ">
                      <div className="top-adverties"></div>
                      <h4 className="side-heading">TRENDING VIDEO</h4>
                      <div className="side-video-img mt-4">
                        <TrendingVideo videoId={TrendingVideoId} />
                      </div>
                      <div className="side-video-img mt-4">
                        <MailchimpFormComponent
                          category={mainPostCategorySlug}
                        />
                      </div>
                      {this.state.hasSpecials ? (
                        <div className="top-adverties video-container">
                          <h4 className="side-heading">SPECIALS</h4>
                          {mainPostCategorySlug !== "" ? (
                            <SpecialsComponent
                              section="network"
                              category={mainPostCategorySlug}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        ``
                      )}

                      {/* <InstaFeeds token={this.insta_token} limit={9} category={'network'}/> */}
                      <div>
                        <div
                          className="top-adverties"
                          style={
                            {
                              // position: "sticky",
                              // top: 0,
                            }
                          }
                        >
                          <div id="bannerAd_sas_119313_11111"></div>
                        </div>
                        <div className="top-adverties">
                          <div id="bannerAd_sas_119311_11111"></div>
                        </div>
                        <div className="top-adverties">
                          <div id="bannerAd_sas_119312_11111"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <InfiniteScroll
                dataLength={this.state.ReletedPaginatioPost.length}
                next={this.loadReletedPaginatioPosts}
                hasMore={this.state.hasMore}
                className="row"
                loader={
                  <div
                    className="col-md-8"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <img
                        placeholder={loadingSVG}
                        src={loadingSVG}
                        className="img-fluid img-responsive  bottom-img"
                        alt="alt"
                      />
                    </div>
                  </div>
                }
                endMessage={
                  <div className="col-md-12">
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  </div>
                }
              >
                {this.state.ReletedPaginatioPost.map((rPost, index) => (
                  <div>
                    <div className="top-adverties">
                      <p style={{ visibility: "hidden" }}>sas ads top</p>
                      <div id={rPost.randDivIdTop}> </div>
                    </div>
                    <div
                      className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 singleRfirstArticle"
                      key={rPost.ID}
                      id={rPost.ID}
                    >
                      <h1
                        className="super-heading"
                        data-toggle="tooltip"
                        title={rPost.post_title}
                      >
                        {rPost.post_title !== null && rPost.post_title !== ""
                          ? rPost.post_title.length > 120
                            ? rPost.post_title.substr(0, 120) + `...`
                            : rPost.post_title
                          : ``}
                      </h1>
                      <div className="main-img-box">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <ImageResize
                              state={this.state}
                              serie={rPost}
                              className="img-fluid w-100 card-img-top"
                              alter={rPost.post_image.image_alt}
                            />
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="main-read-heading clearfix">
                            <span className="main-read-heading-span d-block">
                              {rPost.publish_date}
                            </span>
                            <span className="main-read-heading-category-span">
                              {rPost.post_category[0] &&
                              rPost.post_category[0].cat_name !== null
                                ? rPost.post_category[0].cat_name
                                : ``}
                            </span>
                            <span className="main-read-heading-author-span">
                              By{" "}
                            </span>
                            <Link to={`/author/${rPost.author_login}`}>
                              <span className="main-read-heading-author-span bio-link">
                                {Post.author_name}
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="main-box-content">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: rPost.post_content,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="social-sharing social-container">
                            <FacebookShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV - " + rPost.post_title}
                              hashtag={
                                rPost.post_category[0] &&
                                Post.post_category[0].cat_name !== null
                                  ? `#LATV-${rPost.post_category[0].cat_name}`
                                  : `#LATV-${rPost.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <FacebookIcon size={32} />
                            </FacebookShareButton>
                            {/* bgStyle="fill(red)" */}
                            <TwitterShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV -" + rPost.post_title}
                              hashtag={
                                rPost.post_category[0] &&
                                rPost.post_category[0].cat_name !== null
                                  ? `#LATV-${Post.post_category[0].cat_name}`
                                  : `#LATV-${rPost.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <TwitterIcon size={32} />
                            </TwitterShareButton>
                            <LinkedinShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV -" + rPost.post_title}
                              hashtag={
                                rPost.post_category[0] &&
                                rPost.post_category[0].cat_name !== null
                                  ? `#LATV-${rPost.post_category[0].cat_name}`
                                  : `#LATV-${rPost.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <LinkedinIcon size={32} />
                            </LinkedinShareButton>
                            <EmailShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV -" + rPost.post_title}
                              hashtag={
                                rPost.post_category[0] &&
                                rPost.post_category[0].cat_name !== null
                                  ? `#LATV-${rPost.post_category[0].cat_name}`
                                  : `#LATV-${rPost.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <EmailIcon size={32} />
                            </EmailShareButton>
                          </div>
                        </div>
                      </div>
                      <h4 className="side-heading mt-4 mb-2">
                        {LatestPosts.length >= 1 ? `RELATED ARTICLES` : ``}
                      </h4>
                      <div className="row">
                        {LatestPosts.slice(0, 3).map((post) => {
                          const { post_slug } = post;
                          return post_slug !== Post.post_slug ? (
                            <GridComponent post={post} state={this.state} />
                          ) : (
                            <>
                              <GridArticleComponent />
                              <GridArticleComponent />
                              <GridArticleComponent />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </>
          ) : (
            <></>
          )}
        </div>
      </section>
    );
  }
}
export default withParams(SReadComponent);
