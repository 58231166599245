import { useState } from 'react';
import mailApi from '../../apiConnections/mailchimp';
import "../../styles/mailchimp.css"


const MailchimpFormComponent = ( { category }) => {
var tag = category;
// console.log(tag)
if(tag.includes('-')){
    var maintag = tag.split("-",1)
    maintag = maintag[0];
    const subtag = tag
    tag = [maintag,subtag]
}

  const [ emailError, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);
  const [ emailSuccess, setSuccess ] = useState(null);


  const handleEmailChange = event =>{
    setSuccess( null )
    var email = event.target.value;
    setEmail(event.target.value);
    // console.log(email);
    //Email
      if(!email){
        setError( 'Email address should not be empty' );
      } else if((typeof email !== "undefined")){
          let lastAtPos = email.lastIndexOf('@');
          let lastDotPos = email.lastIndexOf('.');
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          
          if (!pattern.test(email)) {
            setError( 'Invalid Email address' );    
          } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            setError( 'Invalid Email address' );  
          } else{
            setError( null );   
            setEmail(event.target.value);
          }
      }  
  } 

  const handleSubmit = event => {
    event.preventDefault();
    if(handleValidation()){
        console.log(`/subscribe/${email}/${tag}`);
        mailApi.get(`/subscribe/${email}/${tag}`).then(res => {
            if(res.data.success){
              var response = res.data.message;
              // console.log("Se suscribío",response);
              var responseBody = JSON.stringify(response.body)
              // console.log(responseBody)
              const ContainsError = responseBody.includes("error\\");
              if(!ContainsError){
                setSuccess( 'Your subsribe request was successful!' );
              } else{
                var responseBodyArray = responseBody.split(",")
                // console.log(responseBodyArray[3]);
                setSuccess( null );   
                setError( (responseBodyArray[3].slice(12)).replace('.\\"','') );
              }
            } else {
                setError( 'There was an error' )
                console.log("no se suscribio",res.data.error);
            }
          }).catch(error => {
            console.log(error.message)
          })
    }else{
      return;
    }
  }

  const handleValidation  = () =>{
    let formIsValid = true;
    //Email
    if(!email){
       formIsValid = false;
       setError( 'Email address should not be empty' );                  
    }else if(typeof email !== "undefined"){
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
      if (!pattern.test(email)) {
        formIsValid = false;
        setError( 'Invalid Email address' );      
      } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        formIsValid = false;
        setError( 'Invalid Email address' );
      } 
   }  
   return formIsValid;
}

  return (
    <div>
        <div className='box'>
          <div className="row titleContainer">
            <h1 className='title'>JOIN OUR NEWSLETTER</h1>
          </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col">
                    <form onSubmit = { handleSubmit } id="subscribe-form" method="post" className='row'>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 centerText">
                        <p className='upperText'>Sign up for our newsletter to stay up with the latest in culture</p>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 centerText" >
                        <div className='row inlineBlock'><input required="" id="subscribe_email" name="subscribe_email"  className='emailInput' type="email" placeholder='username@domain.tld' onChange= {handleEmailChange}></input></div>
                        { !!emailError ? 
                        <div className='row error'><span>{emailError}</span></div>
                         : null }
                        { !!emailSuccess ? 
                        <div className='row' style={{display:`block` , marginBottom:`15px`}}><span style={{color: `green`}}>{emailSuccess}</span></div>
                         : null }
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 centerText">
                        <button 
                        type="submit" 
                        className="newsletterBtn d-flex subscribeButton"  
                        onClick={handleSubmit}
                        >SUBSCRIBE</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default MailchimpFormComponent